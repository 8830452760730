.intropage{
    display: grid;
    grid-gap:  20px;
    grid-template-rows: 1fr;
    justify-content: center;
    margin-top: 15%;
    font-size: 25px;
    
}
.intropage img{
     width: 100%;
     height: 50px;
}
.logo{
    margin-top: 15px;
    margin-left: 30px;
}


.introButton{
    
    padding: 10px 60px 10px 60px;
    background-color: #FDDE5D;
    border: 1px solid #FDDE5D;
    border-radius: 20px;
    font-size:20px;
    font-weight: 800;
    text-align: center;
    
   
}

.newTabs{
    display: grid;
    grid-template-columns: 200px 200px;
    font-size: 20px;
    background-image: url('../media/newtabintro.png');
    background-size: cover;
    text-align: center;
    padding-top: 15px;
    padding-bottom: 15px;
    
}